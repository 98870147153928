import { TermsProps } from '../../@interface/terms'

export const dataTerms: TermsProps = {
  title: 'Termos e Condições da Campanha Boleto com Incentivo',
  subtitle: 'da Campanha Boleto com Incentivo',
  update: '20 de Setembro de 2023',
  about: {
    title: 'Termos e Condições da Campanha Boleto com Incentivo',
    introduction: `
      A campanha “Boleto com Incentivo” é realizada pela Cora Sociedade de Crédito S.A., inscrita no CNPJ sob o nº 37.880.206/0001-63, com sede na Cidade de São Paulo, Estado de São Paulo, Rua Frei Caneca, 1246, 3º andar, sala 31 - Consolação - São Paulo - SP – CEP 01307-002, doravante denominada simplesmente “Cora”.
      <br/><br/>
      Para os fins desta campanha os seguintes termos possuirão os seguintes significados:
      <br/><br/>
      <b>Aplicativo Cora:</b> Aplicativo mobile disponibilizado pela Cora para que você possa gerenciar a Conta Cora de sua empresa.
      <br/>
      <b>Conta Cora:</b> é a conta de pagamento digital pré-paga destinada à realização de transações de pagamento.
      <br/>
      <b>Novo Cliente Cora:</b> Todo aquele Usuário do Aplicativo Cora, que realizou a abertura de sua nova Conta Cora, ou seja, um (01) CNPJ cadastrado pela primeira vez, pelo Aplicativo Cora, em até 14 (quatorze) dias da emissão do boleto com Voucher de Incentivo. O conceito também se aplica à expressão “Usuário”.
      <br/>
      <b>Voucher de Incentivo:</b> O Voucher de Incentivo será no valor de R$ 100,00 (cem reais) a ser disponibilizado como recompensa na Conta Cora após a realização da primeira movimentação da conta pelo Novo Cliente Cora.
      <br/>
      <b>Boleto com Incentivo:</b> Boleto para pagamento recebido pelo Novo Cliente Cora com o Voucher de Incentivo para abertura de sua Conta Cora.`,
  },
  section: [
    {
      list: [
        {
          head: 'Do objeto da campanha',
          items: [
            {
              item: `A campanha consiste no envio de Boleto com Incentivo para que o Novo Cliente Cora abra sua Conta Cora e receba a recompensa do Voucher de Incentivo de R$ 100,00 (cem reais) em conta após realizar a primeira movimentação.`,
              child: [
                {
                  item: 'Para receber o valor do Voucher de Incentivo, o Novo Cliente Cora precisará abrir conta em até 14 dias após a data de emissão do Boleto com Incentivo e realizar qualquer movimentação de valores na nova Conta Cora nesse período.'
                },
                { item: 'O Voucher de Incentivo é válido apenas para o CNPJ destinatário daquele Boleto com Incentivo. A abertura da Conta Cora e o pagamento do boleto por outro CNPJ não gera o pagamento do Voucher de Incentivo.' },
                { item: 'O valor do Voucher de Incentivo será depositado na Nova Conta Cora em até 15 dias após o Novo Cliente Cora realizar qualquer movimentação de valores na nova Conta Cora, dentro do prazo da Cláusula 1.1.1.' }
              ]
            },
          ],
        },
        {
          head: 'Condições para a participacão',
          items: [
            {
              item: `São condições cumulativas para participação na campanha:
              <br/>
              <b>a)</b> O Novo Cliente Cora estar de acordo com os <a href="https://www.cora.com.br/termos-uso/">Termos e Condições de Uso</a> e <a href="https://www.cora.com.br/politica-privacidade/">Política de Privacidade</a> da Cora;
              <br/>
              <b>b)</b> O Novo Cliente Cora estar com o seus dados junto ao cadastro no Aplicativo Cora corretos e atualizados;
              <br/>
              <b>c)</b> O Novo Cliente Cora abrir sua Conta Cora em até 14 (quatorze) dias do recebimento do boleto com Voucher de Incentivo e realizar qualquer movimentação de valores na nova Conta Cora, dentro do prazo da Cláusula 1.1.1.
              `,
            },
          ],
        },
        {
          head: 'Do prazo e da área de execução da campanha',
          items: [
            {
              item: `Esta campanha será válida para boletos emitidos nos dias 21 a 26 de Setembro de 2023, em todo o território nacional.`,
            },
          ],
        },
        {
          head: 'Restrições da campanha',
          items: [
            {
              item: `Não será devido o Voucher de Incentivo de R$ 100,00 (cem reais) se o Novo Cliente Cora não abrir sua Conta Cora dentro de 14 (quatorze) dias da emissão do  Boleto com Incentivo.`,
            },
            {
              item: `Não será devido o valor de R$ 100,00 (cem reais) do Voucher de Incentivo caso fique constatada fraude na campanha, como por exemplo, mas não se limitando, o cadastro indevido feito com dados de terceiro.`,
            },
          ],
        },
        {
          head: 'Da apuração do Voucher de Incentivo',
          items: [
            {
              item: `Os Usuários que atenderem às condições da Cláusula 2 poderão receber Voucher de Incentivo, após apuração realizada pela Cora, conforme Cláusula 5.2. É importante ressaltar que o valor de R$ 100,00 (cem reais) é devido apenas à 01 (um) CNPJ do Novo Cliente Cora.`,
            },
            {
              item: `O recebimento do Voucher de Incentivo de R$ 100,00 (cem reais) será feito em até 15 (quinze) dias após a abertura da Conta Cora, conforme regramento de participação da Cláusula 2.`,
            },
            {
              item: `O Novo Cliente Cora reconhece que a Cora não poderá ser responsabilizada por eventual atraso no recebimento do Voucher de Incentivo de R$ 100,00 (cem reais), causado pelo próprio cliente, como, por exemplo, mas não se limitando, em razão do cadastro de dados incorretos ou não aceitáveis ou outras inconsistências cadastrais.`,
            },
            {
              item: `O Voucher de Incentivo de R$ 100,00 (cem reais) não será concedido em casos de:
              <br/>
              <b>a)</b> uso da Conta Cora para atividades ilegais e/ou suspeitas;
              <br/>
              <b>b)</b> encerramento da Conta Cora antes do prazo de apuração do Voucher de Incentivo;
              <br/>
              <b>c)</b> ou qualquer atividade em desacordo com os <a href="https://www.cora.com.br/termos-uso/">Termos de Uso da Conta</a>.
              <br/><br/>
              A campanha “Boleto com Incentivo” independe de qualquer modalidade de sorteio, vale-brinde, concurso ou operação assemelhada, não estando, portanto, sujeito à autorização prévia estabelecida no artigo 1º da Lei Federal nº 5.768/71, beneficiando indistintamente a todos os que cumprirem ao disposto neste Regulamento.
              <br/><br/>
              <b>Ao participar da campanha, você concorda com o presente Termos e Condições para a campanha “Boleto com Incentivo”.</b>
              `,
            },
          ],
        },
      ],
    },
  ],
}
